import axios from 'axios';
import { SET_EMAILS, LOADING_DATA, SET_ERRORS } from '../types';

// Check email

export const checkEmails = (emails) => (dispatch) => {
  dispatch({
    type: LOADING_DATA,
  });

  axios
    .post('/check', emails)
    .then((res) => {
      dispatch({
        type: SET_EMAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
