import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import axios from 'axios';

// Redux stuff
import { Provider } from 'react-redux';
import store from './redux/store';

// Components
import Navbar from './components/layout/Navbar';

// pages
import home from './pages/home';
import './App.css';
import themefiles from './util/theme';

const theme = createMuiTheme(themefiles);

axios.defaults.baseURL = 'https://evalidation.dev/api/';

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <Navbar />
            <div className="container">
              <Switch>
                <Route exact path="/" component={home} />
              </Switch>
            </div>
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
