import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, green, blue, grey } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Box } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 550,
    margin: '0 auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  mx: {
    backgroundColor: blue[500],
    margin: '8px auto',
    marginTop: '3px',
  },

  mxZero: {
    backgroundColor: grey[500],
    margin: '8px auto',
    marginTop: '3px',
  },
}));

export default function EmailResultCard({ cardWidth }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={
          <Typography
            noWrap
            variant="h5"
            style={{ width: cardWidth, textAlign: 'center' }}
          >
            петрович@почта.рф
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box>
              <CheckCircleRoundedIcon
                style={{ color: green[500], fontSize: 46 }}
              />
              <Typography variant="body2" color="textSecondary" component="p">
                Статус
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <ErrorRoundedIcon style={{ color: red[500], fontSize: 46 }} />
              <Typography variant="body2" color="textSecondary" component="p">
                RFC
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <Avatar className={10 !== 0 ? classes.mx : classes.mxZero}>
                10
              </Avatar>
              <Typography variant="body2" color="textSecondary" component="p">
                MX
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table aria-label="purchases">
            <TableBody>
              {/* {row.history.map((historyRow) => ( */}
              <TableRow>
                <TableCell component="th" scope="row">
                  ASCII
                </TableCell>
                <TableCell component="th" scope="row">
                  петрович@xn--80a1acny.xn--p1ai
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UNICODE
                </TableCell>
                <TableCell component="th" scope="row">
                  петрович@почта.рф
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  MX записи
                </TableCell>
                <TableCell component="th" scope="row">
                  Host: 78.108.81.1.; Pref: 78.108.81.1.
                </TableCell>
              </TableRow>
              {/* ))} */}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}
