import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// MUI
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

class Navbar extends Component {
  render() {
    return (
      <AppBar>
        <Toolbar className="nav-container">
          <>
            <Button color="inherit" component={Link} to="/">
              Главная
            </Button>

            <Button color="inherit" component={Link} to="/login">
              Войти
            </Button>

            <Button color="inherit" component={Link} to="/signup">
              Регистрация
            </Button>
          </>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Navbar;
