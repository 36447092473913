import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

// Redux stuff
import { connect } from 'react-redux';

// Meterial stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChipInput from 'material-ui-chip-input';
import Snackbar from '@material-ui/core/Snackbar';
import AppIcon from '../images/icon.png';
import { checkEmails } from '../redux/actions/dataActions';
import EmailResultCard from '../components/common/EmailResultCard';

const styles = {
  form: {
    textAlign: 'center',
  },
  checkResult: {
    maxWidth: 500,
    margin: '15px auto 15px auto',
  },
  image: {
    width: 120,
    height: 120,
    margin: '15px auto 15px auto',
    marginTop: '100px',
    backgroundSize: 50,
    borderRadius: '50%',
  },
  pageTitle: {
    margin: '15px auto 15px auto',
  },
  textField: {
    margin: '10px auto 10px auto',
  },
  button: {
    margin: '10px auto 10px auto',
    position: 'relative',
  },
};
class home extends Component {
  constructor() {
    super();
    this.state = {
      emails: [],
    };
  }

  handleChips = (event) => {
    if (event.length > 10) {
      const e = event;
      this.setState({ open: true });
      e.length = 10;
      this.setState({
        emails: e,
      });
      return false;
    }
    this.setState({ open: false });
    this.setState({
      emails: event,
    });
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      emails: event,
    };

    this.props.checkEmails(data, this.props.history);
  };

  render() {
    const {
      classes,
      UI: { loading },
    } = this.props;
    return (
      <>
        <Grid container className={classes.form}>
          <Grid item sm />
          <Grid item sm>
            <img src={AppIcon} alt="Icon" className={classes.image} />
            <Typography variant="h5" className={classes.pageTitle}>
              Проверка почты
            </Typography>
            <form noValidate onSubmit={this.handleSubmit}>
              <ChipInput
                placeholder={
                  this.state.emails.length > 0 ? '' : 'петрович@мояпочта.рф'
                }
                fullWidth
                onChange={(chips) => this.handleChips(chips)}
                onUpdateInput={(chips) => this.handleChips(chips)}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                Проверить
              </Button>
            </form>
          </Grid>
          <Grid item sm />
        </Grid>
        <Grid container className={classes.form}>
          <Grid item sm />
          <Grid item sm>
            <EmailResultCard />
            <br />
            <EmailResultCard />
          </Grid>
          <Grid item sm />
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={3000}
          message="Превышено ограничение количества единовременно проверяемых адресов (максимум -- 10 адресов)"
          onClose={() => this.setState({ open: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  UI: state.UI,
});

const mapActionsToProps = {
  checkEmails,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(home));
