import { SET_EMAILS, LOADING_DATA } from '../types';

const initialstate = {
  emails: [],
  loading: false,
};

export default function (state = initialstate, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };

    case SET_EMAILS:
      return {
        ...state,
        emails: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
